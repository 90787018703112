// extracted by mini-css-extract-plugin
export var startNowContentContainer = "mobile-usecase-footer-module--startNowContentContainer--HJWgS";
export var startNowTitle = "mobile-usecase-footer-module--startNowTitle--DrRfn";
export var startNowDesc = "mobile-usecase-footer-module--startNowDesc--8qOeS";
export var priceCard = "mobile-usecase-footer-module--priceCard--qT-qH";
export var priceCardTitle = "mobile-usecase-footer-module--priceCardTitle--97VUU";
export var priceCardDesc = "mobile-usecase-footer-module--priceCardDesc--+AZIY";
export var price = "mobile-usecase-footer-module--price--2V4Ce";
export var priceCardFeatures = "mobile-usecase-footer-module--priceCardFeatures--ON4Hw";
export var priceCardFeature = "mobile-usecase-footer-module--priceCardFeature--UGuRU";
export var startNowButton = "mobile-usecase-footer-module--startNowButton--sD+cV";
export var darkBg = "mobile-usecase-footer-module--darkBg--4scji";
export var relatedContentsContainer = "mobile-usecase-footer-module--relatedContentsContainer--XV2ip";
export var relatedContentsTitle = "mobile-usecase-footer-module--relatedContentsTitle--7ZKg2";
export var postCards = "mobile-usecase-footer-module--postCards--6NthN";
export var postCardWrapper = "mobile-usecase-footer-module--postCardWrapper--Ukjjo";
export var postCard = "mobile-usecase-footer-module--postCard--L7Qm0";
export var postCardCategory = "mobile-usecase-footer-module--postCardCategory--vJSrm";
export var postCardTitle = "mobile-usecase-footer-module--postCardTitle--8MnnU";
export var postCardDesc = "mobile-usecase-footer-module--postCardDesc--57PD0";
export var postCardDescription = "mobile-usecase-footer-module--postCardDescription--KKLPn";