// extracted by mini-css-extract-plugin
export var startNowContentContainer = "desktop-usecase-footer-module--startNowContentContainer--o9eCX";
export var startNowTitle = "desktop-usecase-footer-module--startNowTitle--tUMQM";
export var startNowDesc = "desktop-usecase-footer-module--startNowDesc--9Dgxw";
export var priceCards = "desktop-usecase-footer-module--priceCards--JqkwQ";
export var priceCard = "desktop-usecase-footer-module--priceCard--F+eHg";
export var priceCardTitle = "desktop-usecase-footer-module--priceCardTitle--s1V8h";
export var priceCardDesc = "desktop-usecase-footer-module--priceCardDesc--76ymB";
export var price = "desktop-usecase-footer-module--price--WBzhP";
export var priceCardFeatures = "desktop-usecase-footer-module--priceCardFeatures--V6h17";
export var priceCardFeature = "desktop-usecase-footer-module--priceCardFeature--rymlC";
export var startNowButton = "desktop-usecase-footer-module--startNowButton--4702q";
export var relatedContentsContainer = "desktop-usecase-footer-module--relatedContentsContainer--Z50zf";
export var relatedContentsTitle = "desktop-usecase-footer-module--relatedContentsTitle--ExQtR";
export var postCards = "desktop-usecase-footer-module--postCards---yiCq";
export var postCardWrapper = "desktop-usecase-footer-module--postCardWrapper--8m-m4";
export var postCard = "desktop-usecase-footer-module--postCard--s6DCt";
export var postCardCategory = "desktop-usecase-footer-module--postCardCategory--3qt6C";
export var postCardTitle = "desktop-usecase-footer-module--postCardTitle--OqDGJ";
export var postCardDescription = "desktop-usecase-footer-module--postCardDescription--egqPX";